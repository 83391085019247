<template>
<div class="overflow-hidden h-12 flex flex-col items-end">
    <van-tag class="mb-2" v-for="(info, index) in content" :key="index" color="#6665a3">{{info}}</van-tag>
</div>
</template>

<script>
import {
    Tag
} from "vant";

export default {
    components: {
        [Tag.name]: Tag
    },
    props: {
        content: Array
    }
}
</script>
