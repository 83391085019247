<template>
<van-image v-for="(teacher, index) in teachers" :key="index" fit="cover" :radius="10" :src="Env.userPath + teacher.icon" :class="sizeClass" />
</template>

<script>
import {
    Image as VanImage
} from "vant";
import Env from "@/logics/Envs.js";

export default {
    components: {
        [VanImage.name]: VanImage
    },
    props: {
        teachers: Array,
        sizeClass: Array
    },
    setup() {
        return {
            Env
        };
    }
}
</script>
