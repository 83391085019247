<template>
    <div class="pt-16 flex flex-col items-center">
        <div class="px-8 md:w-1/2 w-full">
            <!-- <van-search v-model="search" placeholder="请输入搜索关键词" background="#cbcbe2" /> -->
        </div>
        <div class="pt-16 flex flex-wrap justify-center">
            <class-card class="cursor-pointer" v-for="(c, index) in classList" :key="index" :details="c" @click="visit(c)"
                :lang-code="langCode" />
        </div>
    </div>
</template>

<script>
import lang from "@/langs/PageClassSearchLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Search
} from "vant";
import ClassCard from "@/components/ClassCard.vue";
import ListClass from "@/asyncs/ListClassBasics.js";
import GetSession from "@/asyncs/GetSession.js";

export default {
    name: "PageClass",
    components: {
        [Search.name]: Search,
        ClassCard
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("hideMenu");
        const router = useRouter();
        const route = useRoute();
        const classList = ref([]);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                store.commit("showMenu");
                store.commit("setPageTitle", lang[store.state.lang].title);
            }, true);
        });
        onMounted(async function () {
            classList.value = await ListClass(route.params.school);
            classList.value.sort(function (a, b) {
                let rankA = 0;
                let rankB = 0;
                let usingRank = false;
                if (a.rank) {
                    rankA = a.rank;
                    usingRank = true;
                }
                if (b.rank) {
                    rankB = b.rank;
                    usingRank = true;
                }
                if (usingRank) {
                    return rankB - rankA;
                }
                const dateOne = a.classDates.start.year * 10000 + a.classDates.start.month * 100 + a.classDates.start.day;
                const dateTwo = b.classDates.start.year * 10000 + b.classDates.start.month * 100 + b.classDates.start.day;
                return dateTwo - dateOne;
            });
        });
        const search = ref("");
        const visit = function (c) {
            router.push("/classDetails/" + c.classId);
        };
        return {
            lang,
            langCode: computed(() => store.state.lang),
            classList,
            search,
            visit
        };
    }
};
</script>
