<template>
    <div class="my-card my-shadow rounded-md w-80 m-5 pb-2" :key="details.classId">
        <img class="h-36 w-full rounded-t-md object-cover" :src="Env.classPath + details.cover" />
        <div class="p-3">
            <div class="text-xl pb-4 text-center">{{ className }}</div>
            <div class="text-sm text-gray-500 mb-7 h-10" v-snip="2">{{ classInfo }}</div>
            <van-divider v-if="details.teachers.length">{{ lang[langCode].teachers }}</van-divider>
            <div class="float-left flex w-36 h-20">
                <teacher-icons :teachers="details.teachers" :sizeClass="['mr-1', 'w-16', 'h-16']" />
            </div>
            <class-meta :content="details.classMetaInfo" />
            <br />
            <van-divider>{{ lang[langCode].plan }}</van-divider>
            <div class="text-center text-sm mb-4">{{ datesText }}</div>
            <session-time v-for="(slot, index) in shownTimes" :key="index" :day="slot.day" :time-start="slot.timeStart"
                :time-end="slot.timeEnd" :lang-code="langCode" :more="details.classTimes.length > 2 && index === 1" />
        </div>
    </div>
</template>

<script>
import lang from "@/langs/ClassCardLang.js";
import Env from "@/logics/Envs.js";
import { computed } from "vue";
import ClassMeta from "@/components/text/ClassMeta.vue";
import SessionTime from "@/components/text/SessionTime.vue";
import TeacherIcons from "@/components/list/TeacherIcons.vue";
import {
    Divider
} from "vant";
import Helpers from "@/logics/Helpers.js";

export default {
    components: {
        ClassMeta,
        SessionTime,
        TeacherIcons,
        [Divider.name]: Divider
    },
    props: {
        details: Object,
        langCode: {
            type: String,
            default: "zh"
        },
    },
    setup(props) {
        const datesText = Helpers.makeDateRange(props.details.classDates.start, props.details.classDates.end, props.langCode);

        const shownTimes = props.details.classTimes.slice(0, 2);

        const className = computed(() => {
            if (!props.details.langs) {
                return props.details.className;
            }
            if (!props.details.langs[props.langCode]) {
                return props.details.className;
            }
            return props.details.langs[props.langCode].className;
        });

        const classInfo = computed(() => {
            if (!props.details.langs) {
                return "";
            }
            if (!props.details.langs[props.langCode]) {
                return "";
            }
            return props.details.langs[props.langCode].classInfo;
        });

        return {
            lang,
            Env,
            datesText,
            shownTimes,
            className,
            classInfo
        };
    }
};
</script>

<style scoped>
.my-card {
    background-color: rgba(255, 255, 255, 0.85);
}
</style>
